import { Box, Flex, Heading, Spacer, Text } from '@chakra-ui/layout';
import React from 'react';
import questions from './questions';

const FrequentlyAskedQuestions: React.FC = () => {
  const questions1 = questions.slice(0, questions.length / 2);
  const questions2 = questions.slice(questions.length / 2);

  return (
    <Box paddingTop="8" paddingBottom="8">
      <Box paddingTop="4">
        <Heading>Frequently Asked Questions</Heading>
      </Box>
      <Flex>
        <Box w="48%">
          {!!questions1.length &&
            questions1.map((question) => (
              <Question
                key={question.question}
                question={question.question}
                answer={question.answer}
              />
            ))}
        </Box>
        <Spacer />
        <Box w="48%">
          {!!questions2.length &&
            questions2.map((question) => (
              <Question
                key={question.question}
                question={question.question}
                answer={question.answer}
              />
            ))}
        </Box>
      </Flex>
    </Box>
  );
};

interface QuestionProps {
  question: string;
  answer: string;
}

const Question: React.FC<QuestionProps> = ({ question, answer }) => {
  return (
    <Box marginTop="6">
      <Heading size="md" marginBottom="4">
        {question}
      </Heading>
      <Text>{answer}</Text>
    </Box>
  );
};

export default FrequentlyAskedQuestions;
