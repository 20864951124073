import React, { useRef } from 'react';
import NextLink from 'next/link';
import router from 'next/router';
import Image from 'next/image';
import {
  Box,
  Button,
  Center,
  Circle,
  Container,
  Flex,
  Heading,
  Stack,
  Spacer,
  Text,
} from '@chakra-ui/react';
import FAQ from 'components/faq';
import LandingLead from 'components/LandingLead';
import YoutubeEmbed from 'components/YouTubeEmbed';
import { useScreenWidth } from 'lib/hooks';

import styles from './Home.module.css';

const LayoutCard: React.FC = ({ children }) => {
  return (
    <Box
      backgroundColor="white"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      margin="4"
      padding="4"
      width={{ md: '30%' }}
    >
      {children}
    </Box>
  );
};

const Home: React.FC = () => {
  const { isLarger } = useScreenWidth(500);

  const videoRef = useRef<HTMLDivElement | null>(null);

  const scrollToRef = (div: HTMLDivElement | null) => {
    div?.scrollIntoView();
  };

  const bannerContent = (
    <Stack spacing="10">
      <Heading color="gray.100" align={isLarger ? 'left' : 'center'}>
        Campsite monitoring and notifications made simple.
      </Heading>
      <Text color="gray.100" fontSize="lg" align={isLarger ? 'left' : 'center'}>
        Create CampWatches to track your favourite campsites and get notified
        the moment they become available.
      </Text>
      <Box>
        <Stack spacing="3">
          <Center>
            <NextLink href="/landing" prefetch={false}>
              <Button onClick={() => router.push('/landing')} width="250px">
                Learn More
              </Button>
            </NextLink>
          </Center>
          <Center>
            <Text color="gray.100" fontSize="lg">
              or
            </Text>
          </Center>
          <Center>
            <Button
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.preventDefault();
                scrollToRef(videoRef.current);
              }}
              width="250px"
            >
              Watch the Video
            </Button>
          </Center>
        </Stack>
      </Box>
    </Stack>
  );

  return (
    <Box>
      <Box className={styles.bannerContainer}>
        <Image
          alt="Mountains"
          src="/static/banner.jpg"
          layout="fill"
          objectFit="cover"
          quality={75}
          priority={true}
        />
        <Flex className={styles.bannerBox}>
          {isLarger ? (
            <Container maxW="container.xl">
              <Box
                maxW="lg"
                marginTop="12"
                marginLeft="12"
                overflow="hidden"
                padding="4"
              >
                {bannerContent}
              </Box>
            </Container>
          ) : (
            <Box
              maxW="lg"
              marginTop="2"
              overflow="hidden"
              padding="4"
              w="475px"
            >
              <Stack spacing="10">{bannerContent}</Stack>
            </Box>
          )}
        </Flex>
      </Box>
      <Box backgroundColor="blue.700">
        <Center paddingTop="12" paddingBottom="12">
          <LandingLead />
        </Center>
      </Box>
      <Container maxW="container.xl">
        <ProblemStatement />
        <ThreeSteps />
      </Container>
      <div ref={videoRef}>
        <ExplainerVideo />
      </div>
      <Container maxW="container.xl">
        <FAQ.TwoCol />
      </Container>
    </Box>
  );
};

const ProblemStatement: React.FC = () => {
  const { isLarger } = useScreenWidth(500);

  return (
    <Box p={4} display={{ md: 'flex' }} justifyContent="center">
      <Stack spacing="8">
        <Center>
          <Heading align={isLarger ? 'start' : 'center'}>
            What is Campwatch?
          </Heading>
        </Center>
        <Container>
          <Stack spacing="6" align="stretch" justify="center">
            <Text style={{ textAlign: 'center' }}>
              Online Campsite reservation systems are increasingly competitive,
              and getting a campsite can require lots of work, patience, and
              most imporantly, luck.
            </Text>
            <Center>
              <Text style={{ textAlign: 'center' }}>
                It&apos;s not uncommon for people to book campsites months in
                advance and cancel their reservation when their plans change.
              </Text>
            </Center>
            <Center>
              <Text style={{ textAlign: 'center' }}>
                With CampWatch you can enter where and when you want to go
                camping, and we&apos;ll monitor your selected campsites for
                these cancellations and notifiy you the moment they are
                available to be re-booked.
              </Text>
            </Center>
          </Stack>
        </Container>
      </Stack>
    </Box>
  );
};

const ThreeSteps: React.FC = () => {
  return (
    <Box p={4} display={{ md: 'flex' }}>
      <LayoutCard>
        <Stack>
          <Center>
            <Circle size="50px" bg="blue.500" color="white">
              <Text fontSize="2xl">1</Text>
            </Circle>
          </Center>
          <Center>
            <Box>
              <Heading size="md">Create a CampWatch</Heading>
            </Box>
          </Center>
          <Center>
            <Box>
              <Text style={{ textAlign: 'center' }}>
                Enter where and when you want to go, along with the rest of the
                details of your desired camping trip.
              </Text>
            </Box>
          </Center>
        </Stack>
      </LayoutCard>
      <Spacer />
      <LayoutCard>
        <Stack>
          <Center>
            <Circle size="50px" bg="blue.500" color="white">
              <Text fontSize="2xl">2</Text>
            </Circle>
          </Center>
          <Center>
            <Box>
              <Heading size="md">Let us handle the Search</Heading>
            </Box>
          </Center>
          <Center>
            <Box>
              <Text style={{ textAlign: 'center' }}>
                We&apos;ll monitor the campsites you choose and if there&apos;s
                a match we&apos;ll send you a text message notification.
              </Text>
            </Box>
          </Center>
        </Stack>
      </LayoutCard>
      <Spacer />
      <LayoutCard>
        <Stack>
          <Center>
            <Circle size="50px" bg="blue.500" color="white">
              <Text fontSize="2xl">3</Text>
            </Circle>
          </Center>
          <Box>
            <Center>
              <Heading size="md">Make the reservation</Heading>
            </Center>
          </Box>
          <Box>
            <Center>
              <Text style={{ textAlign: 'center' }}>
                Head on over to your parks reservation website and create the
                reservation.
              </Text>
            </Center>
          </Box>
        </Stack>
      </LayoutCard>
    </Box>
  );
};

const ExplainerVideo: React.FC = () => {
  return (
    <Box backgroundColor="blue.700">
      <Center paddingTop="12" paddingBottom="12">
        <YoutubeEmbed embedId="dQw4w9WgXcQ" />
      </Center>
    </Box>
  );
};

export default Home;
