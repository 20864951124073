import { getSession, signOut } from 'next-auth/client';
import axios, { AxiosRequestConfig } from 'axios';

let baseURL;
if (process.env.NEXT_PUBLIC_RUN_ENVIRONMENT === 'production') {
  baseURL = 'https://campground-app-nly5hx5tsa-uc.a.run.app';
} else if (process.env.NEXT_PUBLIC_RUN_ENVIRONMENT === 'staging') {
  baseURL = 'https://campwatch-app-staging-nly5hx5tsa-uc.a.run.app';
} else {
  baseURL = 'http://localhost:8000';
}
export const baseUrl = baseURL;

// Axios Api Client
const ApiClient = axios.create(<AxiosRequestConfig>{
  baseURL: baseURL,
  headers: { Accept: 'application/json' },
});

ApiClient.interceptors.request.use(async (config) => {
  const session = await getSession();
  if (!!session?.tokens && !!session?.tokens.accessToken) {
    config.headers.Authorization = `Bearer ${session?.tokens.accessToken}`;
  }
  return config;
});

ApiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // TODO: add a longging statement here
      signOut();
    }
    return Promise.reject(error);
  }
);

export default ApiClient;
