import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import Iframe from 'react-iframe';
import { useScreenWidth } from 'lib/hooks';

interface YoutubeEmbedProps {
  embedId: string;
  width?: number;
}

const YoutubeEmbed: React.FC<YoutubeEmbedProps> = ({
  embedId,
  width = 853,
}) => {
  const { width: screenWidth } = useScreenWidth(width);
  const height = (screenWidth * 480) / 853;

  return (
    <Box margin="4">
      <iframe
        width={screenWidth}
        height={height}
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </Box>
  );
};

export default YoutubeEmbed;
