import React from 'react';
import { Box, Stack, Heading, Text } from '@chakra-ui/layout';
import questions from './questions';

const FrequentlyAskedQuestions: React.FC = () => {
  return (
    <Box padding="8">
      <Box paddingTop="4">
        <Heading>Frequently Asked Questions</Heading>
      </Box>
      <Stack>
        {!!questions.length &&
          questions.map((question) => (
            <Question
              key={question.question}
              question={question.question}
              answer={question.answer}
            />
          ))}
      </Stack>
    </Box>
  );
};

interface QuestionProps {
  question: string;
  answer: string;
}

const Question: React.FC<QuestionProps> = ({ question, answer }) => {
  return (
    <Box paddingTop="6">
      <Heading size="md" marginBottom="4">
        {question}
      </Heading>
      <Text>{answer}</Text>
    </Box>
  );
};

export default FrequentlyAskedQuestions;
