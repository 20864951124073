const questions = [
  {
    question: 'How does it work?',
    answer:
      "We have cloud computing regularly monitor campsites for availabilities. If we detect a match between where you want to go and an available campsite we'll notify you right away so you don't miss out on your next camping adventure. ",
  },
  {
    question: 'What campgrounds does CampWatch support?',
    answer:
      "Currently, CampWatch supports California State Parks, and BC Parks in British Columbia.  We're working hard to expand our coverage, if you don't see your region listed here please reach out and let us know you're interested in using CampWatch",
  },
  {
    question: 'Can I make reservations through CampWatch?',
    answer:
      "We are unable to make reservations on behalf of anyone but with our quick notification system you'll be the first to know when a campsite becomes available. ",
  },
  {
    question: 'Am I guaranteed to make a reservation?',
    answer:
      "We will notify you immediately once a CampWatch has been matched with an available campsite but there is always a chance that someone reserves the campsite before you.  This is why you'll want to make the reservation as soon as you recieve the notification",
  },
  {
    question: 'What if I need help with using CampWatch?',
    answer:
      'We provide email support to all members and will quickly help resolve any issues you might have',
  },
  {
    question: 'Is my information secure?',
    answer:
      "We are PCI compliant, which means we keep all your information safe and secure. We will never sell your information to third party services, and we'll only use it to help you find your next camping adventure. ",
  },
];

export default questions;
