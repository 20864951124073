import React, { useEffect, useRef, useState } from 'react';
import router from 'next/router';
import { Formik, Field, Form, FieldProps, FormikProps } from 'formik';
import {
  chakra,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import ApiClient from 'lib/auth/api';
import { useScreenWidth } from 'lib/hooks';
import * as Yup from 'yup';

const Bold = chakra('span', {
  baseStyle: {
    fontWeight: 'bold',
    color: 'gray.800',
  },
});

const parseIp = (data: string | undefined): string | undefined => {
  // Convert key-value pairs to JSON
  // https://stackoverflow.com/a/39284735/452587
  if (!data) return undefined;

  const parsedData = data
    .trim()
    .split('\n')
    .reduce(function (obj: Record<string, string>, pair: string) {
      const kvPair = pair.split('=');
      const key: string = kvPair[0];
      const value: string = kvPair[1];
      return (obj[key] = value), obj;
    }, {});

  return parsedData?.ip || undefined;
};

interface InfoForm {
  email: string;
  agreeToContact: boolean;
  ipAddress?: string;
}

const initialValues = { email: '', agreeToContact: false };

const InfoFormSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Required'),
});

const LearnMore: React.FC = () => {
  const [submitting, setSubmitting] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formRef = useRef<FormikProps<InfoForm>>(null);
  const toast = useToast();
  const { isLarger, width } = useScreenWidth(475);

  useEffect(() => {
    fetch('https://www.cloudflare.com/cdn-cgi/trace')
      .then((response) =>
        response.text().then((data) => {
          const ipAddress = parseIp(data);
          formRef.current?.setFieldValue('ipAddress', ipAddress);
        })
      )
      .catch(() => {
        //
      });
  }, []);

  const handleSubmit = (values: InfoForm) => {
    setSubmitting(true);
    ApiClient.post('/api/emails/landing/', values)
      .then(() => {
        formRef.current?.resetForm({ values: initialValues });
        onOpen();
        setSubmitting(false);
      })
      .catch(() => {
        const error =
          "Something went wrong.  We've been notified, please try again";
        toast({ title: error, status: 'error', isClosable: true });
        setSubmitting(false);
      });
  };

  return (
    <>
      <Box
        maxW="lg"
        backgroundColor="white"
        borderWidth="1px"
        borderRadius="lg"
        minW={isLarger ? '475px' : width}
      >
        <Stack py="12" px="6">
          <Heading align="center" fontSize="4xl">
            Stay Up to Date With CampWatch
          </Heading>
          <Text align="center" fontSize="lg" color="gray.700">
            We&apos;re building CampWatch as fast as possible. Enter your email
            to get the latest <Bold>updates</Bold> and <Bold>promotions</Bold>
          </Text>
          <Formik
            initialValues={initialValues}
            validationSchema={InfoFormSchema}
            onSubmit={handleSubmit}
            innerRef={formRef}
          >
            <Form>
              <Field name="email">
                {(props: FieldProps<string>) => (
                  <FormControl
                    id="email"
                    isRequired
                    isInvalid={
                      !!props.form.errors.email && !!props.form.touched.email
                    }
                  >
                    <FormLabel mt={4} htmlFor="email">
                      Email address
                    </FormLabel>
                    <Input {...props.field} id="email" placeholder="email" />
                    <FormErrorMessage>
                      {props.form.errors.email}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="agreeToContact">
                {(props: FieldProps<string>) => (
                  <FormControl
                    id="agreeToContact"
                    isInvalid={
                      !!props.form.errors.agreeToContact &&
                      !!props.form.touched.agreeToContact
                    }
                  >
                    <FormLabel mt={4} htmlFor="agreeToContact">
                      Help Us Out (optional)
                    </FormLabel>
                    <Checkbox
                      {...props.field}
                      name="agreeToContact"
                      id="agreeToContact"
                    />
                    <FormHelperText>
                      By checking this box we may reach out by email to ask what
                      features you&apos;d find useful
                    </FormHelperText>
                    <FormErrorMessage>
                      {props.form.errors.agreeToContact}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Button mt={8} w="100%" isLoading={submitting} type="submit">
                Click to Stay Updated
              </Button>
            </Form>
          </Formik>
        </Stack>
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          router.push('/');
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Thank you for your interest in CampWatch!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="4">
              <Text>
                There&apos;s a lot happening at CampWatch and we&apos;re working
                hard to build the best product to help you find your next
                campsite
              </Text>
              <Text>
                Thank your for your support, we&apos;ll be sure to reach out
                without spamming your email inbox
              </Text>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={() => {
                onClose();
                router.push('/');
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LearnMore;
